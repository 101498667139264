import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function getPostHogDistinctId(request: Request) {
    const cookieString = request.headers.get("Cookie") || "";
    const projectAPIKey = import.meta.env.VITE_POSTHOG_KEY;
    const cookieName = `ph_${projectAPIKey}_posthog`;
    const cookieMatch = cookieString.match(new RegExp(`${cookieName}=([^;]+)`));
    let distinctId = undefined;

    if (cookieMatch) {
        const parsedValue = JSON.parse(decodeURIComponent(cookieMatch[1]));
        if (parsedValue?.distinct_id) {
            distinctId = parsedValue.distinct_id;
        } else {
            distinctId = crypto.randomUUID();
        }
    } else {
        distinctId = crypto.randomUUID();
    }
    return distinctId;
}
